<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="flex h-screen" v-if="isLoading">
    <div class="m-auto">
      <Spinner />
    </div>
  </div>
  <div class="bg-gray-100 m-h-screen" v-if="!isLoading">
    <!-- Sidebar for mobile -->
    <TransitionRoot as="template" :show="sidebarOpen">
      <DialogVue
        as="div"
        class="fixed inset-0 flex z-40 md:hidden"
        @close="sidebarOpen = false"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <div
            class="relative flex-1 flex flex-col max-w-xs w-full bg-gray-800"
          >
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  type="button"
                  class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  @click="sidebarOpen = false"
                >
                  <span class="sr-only">Close sidebar</span>
                  <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <div class="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
              <div class="flex-shrink-0 flex items-center px-4">
                <img
                  class="h-8 w-auto"
                  src="/images/logo-on-dark@3x.png"
                  alt="Dashcam"
                />
              </div>
              <nav class="mt-5 px-2 space-y-1">
                <router-link
                  v-for="item in navigation"
                  :key="item.name"
                  :to="item.to"
                  :class="[
                    isActive
                      ? 'bg-gray-900 text-white'
                      : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'group flex items-center px-2 py-2 text-base font-medium rounded-md',
                  ]"
                >
                  <component
                    :is="item.icon"
                    :class="[
                      isActive
                        ? 'text-gray-300'
                        : 'text-gray-400 group-hover:text-gray-300',
                      'mr-4 flex-shrink-0 h-6 w-6',
                    ]"
                    aria-hidden="true"
                  />
                  {{ item.name }} {{ isActive }}
                </router-link>
              </nav>
            </div>
            <div class="flex-shrink-0 flex bg-gray-700 p-4">
              <a href="#" class="flex-shrink-0 group block">
                <div class="flex items-center">
                  <div>
                    <img
                      class="inline-block h-10 w-10 rounded-full"
                      :src="auth.user.picture"
                      alt=""
                    />
                  </div>
                  <div class="ml-3">
                    <p class="text-base font-medium text-white">
                      {{ auth.user.name }}
                    </p>
                    <a
                      href="#"
                      @click="performLogout"
                      class="text-sm font-medium text-gray-400 group-hover:text-gray-300"
                    >
                      Logout
                    </a>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14">
          <!-- Force sidebar to shrink to fit close icon -->
        </div>
      </DialogVue>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex-1 flex flex-col min-h-0 bg-gray-800">
        <div class="flex-0 flex flex-col pt-5 pb-6 overflow-y-auto">
          <div class="flex items-center flex-shrink-0 px-4">
            <img
              class="h-8 w-auto"
              src="/images/logo-on-dark.png"
              alt="Workflow"
            />
          </div>
          <nav class="mt-5 flex-1 px-2 space-y-1">
            <div v-for="item in navigation" :key="item.name">
              <router-link
                v-if="item.type == 'local'"
                :to="item.to"
                v-slot="{ isActive }"
                :class="[
                  item.current
                    ? 'bg-gray-900 text-white'
                    : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                  'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
                ]"
              >
                <component
                  :is="item.icon"
                  :class="[
                    isActive
                      ? 'text-gray-300'
                      : 'text-gray-400 group-hover:text-gray-300',
                    'mr-3 flex-shrink-0 h-6 w-6',
                  ]"
                  aria-hidden="true"
                />
                {{ item.name }}
              </router-link>
              <a
                v-if="item.type == 'remote'"
                :href="item.to"
                class="text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
              >
                <component
                  :is="item.icon"
                  class="text-gray-400 group-hover:text-gray-300 mr-3 flex-shrink-0 h-6 w-6"
                  aria-hidden="true"
                />
                {{ item.name }}
              </a>
            </div>
          </nav>
        </div>

        <div class="space-y-1 flex-1">
          <h3
            class="px-4 text-xs font-semibold text-gray-500 uppercase tracking-wider"
            id="projects-headline"
          >
            Projects
          </h3>
          <div
            class="space-y-1"
            role="group"
            aria-labelledby="projects-headline"
          >
            <router-link
              v-for="project in projects"
              :key="project.id"
              :to="{ name: 'replays', params: { projectId: project.id } }"
              class="group flex items-center mx-2 px-2 py-2 text-sm font-medium text-gray-400 rounded-md hover:bg-gray-700 hover:text-white"
            >
              <span class="truncate">
                {{ project.name }}
              </span>
            </router-link>
          </div>
        </div>

        <div
          class="flex-shrink-0 w-5/6 mx-auto my-5 group block"
          v-if="user.team.members.length === 1"
        >
          <div class="rounded-md bg-gray-50 p-4">
            <div class="flex">
              <div class="flex-shrink-0 mr-2">
                <InformationCircleIcon
                  class="h-5 w-5 text-blue-400"
                  aria-hidden="true"
                />
              </div>
              <div class="flex-1 md:flex-col md:justify-between">
                <p class="text-sm text-blue-700">Dashcam is better together!</p>
                <p class="mt-3 text-sm">
                  <router-link
                    to="/team"
                    class="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
                  >
                    Invite your team →
                  </router-link>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="flex-shrink-0 flex bg-gray-900 p-4">
          <div class="flex items-center">
            <div>
              <img
                class="inline-block h-9 w-9 rounded-full"
                :src="auth.user.picture"
                alt=""
              />
            </div>
            <div class="ml-3">
              <p class="text-sm font-medium text-white">
                {{ auth.user.name }}
              </p>
              <a
                href="#"
                @click="performLogout"
                class="text-xs font-medium text-gray-300 group-hover:text-gray-200"
              >
                Logout
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="md:pl-64 flex flex-col flex-1">
      <div
        class="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-gray-100"
      >
        <button
          type="button"
          class="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
          @click="sidebarOpen = true"
        >
          <span class="sr-only">Open sidebar</span>
          <Bars3Icon class="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <main class="flex-1">
        <!-- Download Banner -->
        <div v-if="showDownloadBanner" class="bg-indigo-600">
          <div class="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8">
            <div class="flex flex-wrap items-center justify-between">
              <div class="flex w-0 flex-1 items-center">
                <span class="flex rounded-lg bg-indigo-800 p-2">
                  <MegaphoneIcon
                    class="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </span>
                <p class="ml-3 truncate font-medium text-white">
                  <span class="md:hidden">
                    Check out the getting started guide!
                  </span>
                  <span class="hidden md:inline">
                    Follow the getting started guide to make your first Dash!
                  </span>
                </p>
              </div>
              <div
                class="order-3 mt-2 w-full flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto"
              >
                <a
                  href="https://docs.dashcam.io/dashcam/setup/download-replayable"
                  class="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-2 text-sm font-medium text-indigo-600 shadow-sm hover:bg-indigo-50"
                  >Read the Guide</a
                >
              </div>
              <div class="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
                <button
                  type="button"
                  class="-mr-1 flex rounded-md p-2 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
                  @click="dismissedBanner = true"
                >
                  <span class="sr-only">Dismiss</span>
                  <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="py-6">
          <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <div class="py-4">
              <router-view v-if="user"></router-view>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { datadogLogs as log } from "@datadog/browser-logs";
import { mapState, mapMutations } from "vuex";

import {
  Dialog as DialogVue,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  FolderIcon,
  Bars3Icon,
  XMarkIcon,
  ChatBubbleLeftRightIcon,
  DocumentTextIcon,
  QuestionMarkCircleIcon,
  MegaphoneIcon,
  CommandLineIcon,
  ArrowDownTrayIcon,
  UserGroupIcon,
} from "@heroicons/vue/24/outline";
import {
  InformationCircleIcon,
  PuzzlePieceIcon,
} from "@heroicons/vue/20/solid";
import EnsureCloud from "../helpers/ensure-cloud";
import Spinner from "../components/Library/Spinner.vue";

const navigation = [
  {
    name: "Projects",
    to: "/projects",
    icon: FolderIcon,
    type: "local",
  },
  {
    name: "Team",
    to: "/team",
    icon: UserGroupIcon,
    type: "local",
  },
  {
    name: "Docs",
    to: "https://docs.dashcam.io/",
    icon: DocumentTextIcon,
    type: "remote",
  },
  {
    name: "Desktop App",
    to: "https://dashcam.io/download",
    icon: ArrowDownTrayIcon,
    type: "remote",
  },
  {
    name: "Chrome Extension",
    to: "https://docs.dashcam.io/replayable/setup/installation",
    icon: PuzzlePieceIcon,
    type: "remote",
  },
  {
    name: "Developer Tools",
    to: "https://docs.dashcam.io/replayable/log-and-error-tracking/getting-started-with-logs",
    icon: CommandLineIcon,
    type: "remote",
  },
  {
    name: "Discord",
    to: "https://discord.gg/cWDFW8DzPm",
    icon: ChatBubbleLeftRightIcon,
    type: "remote",
  },
];

export default {
  components: {
    DialogVue,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    Bars3Icon,
    XMarkIcon,
    ChatBubbleLeftRightIcon,
    DocumentTextIcon,
    QuestionMarkCircleIcon,
    MegaphoneIcon,
    InformationCircleIcon,
    CommandLineIcon,
    ArrowDownTrayIcon,
    PuzzlePieceIcon,
    UserGroupIcon,
    Spinner,
  },
  inject: ["auth"],
  setup() {
    const sidebarOpen = ref(false);

    return {
      navigation,
      sidebarOpen,
    };
  },
  data() {
    return {
      dismissedBanner: false,
      isLoading: true,
    };
  },
  computed: {
    ...mapState(["projects", "user"]),
    replayCount() {
      return _.sumBy(this.projects, "replays");
    },
    // inviteLink() {
    //   return this.user?.invite?.code
    //     ? `https://dashcam.io?betacode=${this.user?.invite?.code}`
    //     : "";
    // },
    showDownloadBanner() {
      return (
        this.projects.length && this.replayCount === 0 && !this.dismissedBanner
      );
    },
  },
  async created() {
    window.analytics?.page();
    EnsureCloud();

    const token = await this.auth.getTokenSilently();
    this.$store.commit("setToken", token);

    const user = await Cloud.whoami()
      .headers({
        Authorization: `Bearer ${this.$store.state.token}`,
      })
      .tolerate((err) => {
        console.error(err);
      });

    if (user) {
      log.logger.info("Logged In Manually");

      window.analytics?.identify(user.id, {
        email: user.profile.email,
        id: user.id,
      });

      log.setLoggerGlobalContext("user", {
        email: user.profile.email,
        id: user.id,
        name: user.profile.name,
      });

      this.$store.commit("setUser", user);

      if (user.role === "owner" && !user.hasOnboarded) {
        this.$router.push({
          name: "onboarding",
        });
      }
    } else {
      log.logger.error("Problem Finding User From Token");
    }

    this.isLoading = false;
  },
  methods: {
    ...mapMutations(["resetApp"]),
    getOrigin() {
      return window.location.origin;
    },
    // copyInviteLink() {
    //   this.$copyText(this.inviteLink).then(
    //     (_e) => {
    //       this.$toast.open({
    //         message: "Copied link to clipboard",
    //         type: "info",
    //       });

    //       log.logger.info("Copied Invite Link", {
    //         user: this.user,
    //       });
    //     },
    //     (e) => {
    //       log.logger.error(e);
    //       this.$toast.open({
    //         message: "There was a problem copying to clipboard",
    //         type: "error",
    //       });
    //     }
    //   );
    // },
    performLogout() {
      this.auth.logout({ returnTo: this.getOrigin() });
      this.resetApp();
    },
  },
};
</script>

<style scoped>
nav .router-link-active {
  @apply text-white;
  @apply bg-gray-900;
}
</style>
